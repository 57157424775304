var config = new (function() {
	this.url_ws = "https://tdm-ws-wldd-tools.pubqlf.co.as8677.net";
	//this.url_ws = "http://localhost:9090";
	this.version = "2.4";
	this.groupComplexId = "3a6ccb3c06a84beaa2dbdf33eb51cd34";
	this.dasso = {
		OAUTH2_SERVER : "https://dasso.kzn.as8677.net",
		CLIENT_CALLBACK : this.url_ws + "/admin/token",
		CLIENT_ID : "e4a8b31071dd26dc67eeb3ca723dec6b37b9d9a6"
	}
})();

export function getConfig(){
	return config;
}

export function isNotNull(val){
	if(val != null && val !== "" && val !== "undefined" && val !== undefined && val !== "-1" && val !== -1){
		if(!Array.isArray(val)){
			return true;
		}else if(val.length > 0){
			return true;
		}
	}
	return false;
}

export function isNull(val){
	if(val == null || val === "" || val === "undefined" || val === undefined || val === "-1" || val === -1){
		return true;
	}else{
		if(!Array.isArray(val)){
			return false;
		}else if(val.length === 0){
			return true;
		}
	}
	return false;
}