import Vue from 'vue';

/*var truncate = function(text, length, clamp){
	clamp = clamp || '...';
	var node = document.createElement('div');
	node.innerHTML = text;
	var content = node.textContent;
	return content.length > length ? content.slice(0, length) + clamp : content;
};

Vue.filter('truncate', filter);*/


Vue.filter('truncate', (text, length, clamp) => {
	clamp = clamp || '...';
	var node = document.createElement('div');
	node.innerHTML = text;
	var content = node.textContent;
	return content.length > length ? content.slice(0, length) + clamp : content;
});