var axios = require("axios");
import {getConfig, isNotNull} from "./utils";
var app = getConfig();

class DemonstrationsApi{
	getAll(){
		let url = app.url_ws + "/demonstration/all?groupComplexId=" + app.groupComplexId;
		return axios.get(url)
	}

	get(complexId){
		let url = app.url_ws + "/demonstration/complexId/" + complexId + "?groupComplexId=" + app.groupComplexId;
		return axios.get(url)
	}

	/*create(data){
		let url = app.url_ws + "/apk";

		let params = new URLSearchParams();
		params.append("data", JSON.stringify(data));

		return axios.post(url, params)
	}*/

	upload(file, onUploadProgress){
		let url = app.url_ws + "/demonstration/upload";

		let params = new FormData();
		params.append("file", file);

		return axios.post(
			url,
			params,
			{
				headers: {
					"Content-Type": "multipart/form-data"
				},
				onUploadProgress
			})
	}

	update(demonstrationInfo) {
		let url = app.url_ws + "/demonstration/edit";

		let params = new URLSearchParams();
		params.append("complexId", demonstrationInfo.complexId);
		params.append("ownerEmail", demonstrationInfo.ownerEmail);
		params.append("longDesc", demonstrationInfo.longDesc);
		params.append("shortDesc", demonstrationInfo.shortDesc);
		params.append("tags", demonstrationInfo.tags);

		if(isNotNull(demonstrationInfo.videoPath))
			params.append("videoPath", demonstrationInfo.videoPath);

		if(isNotNull(demonstrationInfo.docPath))
			params.append("docPath", demonstrationInfo.docPath);

		return axios.post(url, params)
	}

	updateVersion(id, changelog) {
		let url = app.url_ws + "/demonstration/version/edit/changelog";

		let params = new URLSearchParams();
		params.append("id", id);
		params.append("changelog", changelog);

		return axios.post(url, params)
	}

	getFiles() {
		return []
	}

	/*flush() {
		let url = app.url_ws + "/apk/delete/all";

		return axios.post(url)
	}*/

	delete(complexId) {
		let url = app.url_ws + "/demonstration/remove";

		let params = new URLSearchParams();
		params.append("complexId", complexId);

		return axios.post(url, params)
	}

	getIconImageUrl(complexId){
		return app.url_ws + "/demonstration/icon/" + complexId
	}

	getDownloadUrl(filename){
		return app.url_ws + "/demonstration/apk/" + filename
	}

	getGroups(complexId){
		let url = app.url_ws + "/demonstration/get/groups?complexId=" + complexId;
		return axios.get(url)
	}

	getVolumeSize(){
		let url = app.url_ws + "/demonstration/volume/size";
		return axios.get(url)
	}
}

export default new DemonstrationsApi();