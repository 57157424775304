<template>
    <div id="app" class="d-flex flex-column" v-if="isNotNull(token) === true || isDevelopment() === true">

        <b-navbar type="dark" variant="dark" toggleable="md">
            <b-navbar-brand>TerminalDemosManager</b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse is-nav id="nav-collapse">
                <b-navbar-nav>
                    <b-nav-item :class="{'currentNavItem' : isCurrentItem('demonstrations')}">
                        <router-link to="/demonstrations" class="nav-link">List demonstrations</router-link>
                    </b-nav-item>
                    <b-nav-item :class="{'currentNavItem' : isCurrentItem('upload')}">
                        <router-link to="/upload" class="nav-link">Upload demonstration</router-link>
                    </b-nav-item>
                    <b-nav-item :class="{'currentNavItem' : isCurrentItem('groups')}">
                        <router-link to="/groups" class="nav-link" v-if="isAdmin">Manage groups</router-link>
                    </b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav class="ml-auto">
                    <b-nav-item @click="dassoLogout()" :disabled="isDevelopment()">
                        Logout
                    </b-nav-item>
                    <b-nav-item id="tooltip-space">
                        {{getFreeSpaceLabel()}}
                    </b-nav-item>
                    <b-tooltip target="tooltip-space" custom-class="custom-tooltip-space">
                        {{getFullFreeSpaceLabel()}}
                        <loading-progress
                                :progress="getUsedSpace()"
                                shape="line"
                                height="6"
                                width="150"
                                size="150"
                        ></loading-progress>
                    </b-tooltip>
                    <b-nav-item>
                        v{{version}}
                    </b-nav-item>

                </b-navbar-nav>
            </b-collapse>
        </b-navbar>

        <b-container class="py-3">
            <router-view />
        </b-container>
    </div>
</template>

<script>
	import DemonstrationsApi from "@/assets/js/DemonstrationsApi";
	import {getConfig} from "@/assets/js/utils";
	var app = getConfig();
	import Dasso from '@/assets/js/Dasso';
	var axios = require('axios');
	export default {
		name: "App",
		data() {
			return {
				token: localStorage.getItem("TDM_token"),
				isAdmin: localStorage.getItem("TDM_isAdmin") === "true",
                version: app.version,
				volumeSizes: null,
			}
		},
        created(){
        },
        methods:{
			dassoLogout(){
				Dasso.dassoLogout()
            },
			isCurrentItem(route){
				return this.$route.name === route
            },
            getVolumeSize(){
				DemonstrationsApi.getVolumeSize()
					.then(response => {
						this.volumeSizes = response.data;
					})
					.catch(e => {
						console.log(e);
					});
            },
			getUsedSpace(){
				if(this.isNotNull(this.volumeSizes)){
					return this.volumeSizes.usedSpace / this.volumeSizes.totalSpace
                }
                return 0
			},
            getFreeSpaceLabel(){
				if(this.isNotNull(this.volumeSizes)){
					let freeSpace = Number(this.volumeSizes.freeSpace);
					if(freeSpace < 1){
						return "Free space " + (freeSpace * 1024).toFixed(2) + "Mo"
                    }
					return "Free space " + freeSpace.toFixed(2) + "Go"
				}
				return ""
            },
            getFullFreeSpaceLabel(){
				if(this.isNotNull(this.volumeSizes)){
					let freeSpace = Number(this.volumeSizes.freeSpace);
					let totalSpace = Number(this.volumeSizes.totalSpace);
					if(freeSpace < 1 && totalSpace < 1){
						return "Free space " + (freeSpace * 1024).toFixed(2) + "Mo/" + (totalSpace * 1024).toFixed(2) + "Mo"
					}else if(freeSpace < 1 && totalSpace > 1){
						return "Free space " + (freeSpace * 1024).toFixed(2) + "Mo/" + totalSpace.toFixed(2) + "Go"
                    }else{
						return "Free space " + freeSpace.toFixed(2) + "Go/" + totalSpace.toFixed(2) + "Go"
                    }
				}
				return ""
            }
        },
		watch:{
			'$route': function() {
				this.token = localStorage.getItem("TDM_token");
                this.isAdmin = localStorage.getItem("TDM_isAdmin") === "true";

				if(this.isNotNull(this.token)) {
					axios.defaults.headers.common['token'] = this.token;
				}else{
					delete axios.defaults.headers.common.Authorization
				}

				this.getVolumeSize()
			}
		}
	};
</script>

<style>
    .custom-tooltip-space{
        opacity: 1 !important;
    }

    .custom-tooltip-space .arrow::before{
        border-bottom-color: white !important;
    }

    .custom-tooltip-space .tooltip-inner{
        background-color: white !important;
        color: black;
        box-shadow: 5px 10px 20px 0 rgba(0,0,0,0.2);
    }

    .vue-progress-path .progress {
        stroke: #343a40 !important;
    }

    .vue-progress-path .background {
        stroke: #ced4da !important;
    }
</style>
