import moment from 'moment';
import 'moment/locale/fr';
moment().locale('fr');

export default {
	methods:{
		isDevelopment(){
			return process.env.NODE_ENV === 'development'
		},
		isEmpty(val){
			return Object.keys(val).length === 0
		},
		isNotNull(val){
			if(val != null && val !== "" && val !== "undefined" && val !== undefined && val !== "-1" && val !== -1){
				if(!Array.isArray(val)){
					return true;
				}else if(val.length > 0){
					return true;
				}
			}
			return false;
		},
		isNull(val){
			if(val == null || val === "" || val === "undefined" || val === undefined || val === "-1" || val === -1){
				return true;
			}else{
				if(!Array.isArray(val)){
					return false;
				}else if(val.length === 0){
					return true;
				}
			}
			return false;
		},
		formatDate(dateString) {
			return moment(dateString, "DD/MM/YYYY HH:mm").format("DD MMM YYYY HH:mm")
		},
	}
}