import Vue from 'vue'
import VueRouter from 'vue-router'
import Dasso from '@/assets/js/Dasso'

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		alias: "/demonstrations",
		name: 'demonstrations',
		component: () => import("@/views/DemonstrationsList"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/upload',
		name: 'upload',
		component: () => import("@/views/UploadDemonstration"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/flush',
		name: 'flush',
		component: () => import("@/views/FlushDemonstrations"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/edit/:complexId',
		name: 'edit',
		component: () => import("@/views/EditDemonstration"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/roles',
		name: 'roles',
		component: () => import("@/views/RolesListTiles"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/pinauth',
		name: 'pinauth',
		component: () => import("@/views/PinAuthentication"),
		meta: {
			requiresAuth: true
		}
	},
	{
		path: '/groups',
		name: 'groups',
		component: () => import("@/views/ManageDemonstrationsGroups"),
		meta: {
			requiresAuth: true,
			requiresAdmin: true
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	let requiresAdmin = to.meta.requiresAdmin;
	let isAdmin = localStorage.getItem("TDM_isAdmin") === "true";

	if(requiresAdmin && !isAdmin){
		next("/")
	}else if(process.env.NODE_ENV !== 'development'){
		Dasso.dassoLogin(to, from, next)
	}else{
		next()
	}
})

export default router
