import Vue from 'vue'
import App from './App.vue'
import router from './router'
import MQTT from '@/assets/js/Mqtt'

import { BootstrapVue, BootstrapVueIcons, ModalPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(ModalPlugin)

import mixin from './mixins'
Vue.mixin(mixin);

import Nl2br from 'vue-nl2br'
Vue.component('nl2br', Nl2br)

import '@/filters.js'

Vue.prototype.$mqtt = MQTT;

require('@/assets/css/stylesheet.css');

Vue.config.productionTip = false

import 'vue-progress-path/dist/vue-progress-path.css'
import VueProgress from 'vue-progress-path'

Vue.use(VueProgress);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
